.main_container{
    width: 100%;
    height: 80vh;
    display: flex;
    background: #fafafa;
    align-items: center;
    margin-top: 100px;
}
.image{
    height: 100%;
    margin-left: 70px;
    box-shadow: 2px 1px 3px 2px #e3dede;
    border-radius: 5px;
}
.text_container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
.text {
    color: #fff;
    font-size: 120px;
    font-family: 'Dancing Script', cursive;
    background: linear-gradient(to right, #0b0b0c 10%, #777777 50%, #bbadad 60%);
    background-clip: border-box;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
    margin-top: -100px;
}
.audio_container{
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}
.audio_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
.audio{
    width: 60%;
    margin-left: 20px;
}
audio::-webkit-media-controls-panel {
    background-color: rgba(56, 45, 126,0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
.title{
    font-size: 25px;
    font-family: 'Dancing Script', cursive;
    margin-right: 50px;
    font-weight: 600;
    color: #382d7e;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.txt{
    width: 80%;
    margin-top: 20px;
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
}
@media screen and (max-width: 1300px){
    .text{
        font-size: 100px;
    }
    .txt{
        font-size: 30px;
    }
}
@media screen and (max-width: 980px){
    .text{
        font-size: 80px;
    }
    .txt{
        font-size: 20px;
    }
}
@media screen and (max-width: 880px){
    .text{
        font-size: 60px;
    }
    .txt{
        font-size: 20px;
    }
}
@media screen and (max-width: 750px){
    .image{
         width: 70%;
     }
    .text{
        font-size: 40px;
    }
    .txt{
        font-size: 18px;
    }
    .title{
        font-size: 15px;
    }
    .audio{
        height: 40px;
    }
    .main_container{
        display: table;
        height: 100%;
        margin-top: 20px;
    }
    .text_container{
        margin-top: 100px;
        /*height: auto;*/
        /*position: relative;*/
    }
}
@media screen and (max-width: 630px){
    /*.image{*/
    /*    width: 400px;*/
    /*    height: 400px;*/
    /*}*/
    .text{
        font-size: 20px;
    }
    .txt{
        font-size: 12px;
    }
}
@media screen and (max-width: 480px){
    /*.image{*/
    /*    width: 300px;*/
    /*    height: 300px;*/
    /*}*/
    .text{
        font-size: 20px;
    }
    .txt{
        font-size: 12px;
    }
}