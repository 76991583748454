
.container{
    width: 100%;
    height: 100%;
    background: #e4e3e1;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 70px;
    overflow: hidden;
}
.carousel{
    width: 100%;
    height: 100%;
    margin: 40px 0;
    background: #e4e3e1;
    padding: 30px 0;
}
.logo{
    width: 200px;
    height: 200px;
    margin: 0 auto;
    padding: 0;
    border-radius: 100%;
}
.logo_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.image{
    width: 600px;
    display: flex;
    justify-content: center;
}
.img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient( to right,#e4e3e1 50%, #f9f9fb 50%);
}
.text{
    width: 100%;
    height: 100%;
}
.title{
    width: 100%;
    font-size: 40px;
    font-family: 'Dancing Script', cursive;
    display: flex;
    justify-content: center;
    color: #2f266c;
    margin-top: 10px;
}
.txt{
    width: 90%;
    height: 100%;
    margin:  20px auto 0;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

.list{
    margin-left: 40px;

}
.static{
    width: 100%;
    height: 30vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.div{
    width: 60%;
    height: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: rgba(47, 38, 108,0.1);
    align-items: center;
    font-size: 30px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 4px 1px 2px 3px rgba(43,38,108,0.5);
    font-family: 'Dancing Script', cursive;
    font-weight: 500;
}
.number{
    font-size: 35px;
    color: #2f266c;
    font-weight: 800;
}
.slide{
    background-color: #1883ba;
}
.react-3d-carousel{
    background-color: #00b3ff;
    height: 100%;
}

.contract_container{
    width: 100%;
    height: 50vh;
    background: rgba(47, 38, 108,0.1);
    margin-top: 50px;
    display: flex;
    /*display: grid;*/
    flex-flow: column;
    /*justify-content: space-evenl;*/
    align-items: center;
}
.contract_container>div{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.header{
    font-size: 100px;
    text-align: center;
    font-family: 'Joane Stencil Regular';
    font-weight: 400;
    color:  #382d7e;
    font-style: normal;
}
.contract{
    width: 200px;
    height: 200px;
    border-radius: 100%;
}
.contract>img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

@media screen and (max-width: 1900px){
   .image{
       width: 500px;
   }
    .txt{
        font-size: 18px;
        line-height: 25px;
    }
}
@media screen and (max-width: 1700px){
    .txt{
        font-size: 15px;
        line-height: 22px;
    }
    .image{
        width: 450px;
    }
    .div{
        font-size:20px ;
    }
}
@media screen and (max-width: 1400px){
    .txt{
        font-size: 14px;
        line-height: 20px;
    }
    .image{
        width: 400px;
    }
}
@media screen and (max-width: 1200px){
    .txt{
        font-size: 12px;
        line-height: 18px;
    }
    .image{
        width: 350px;
    }
    .div{
        font-size: 15px;
    }
    .number{
        font-size: 30px;
    }
    .logo{
        width: 150px;
        height: 150px;
    }
    .header{
        font-size: 80px;
    }
}

@media screen and (max-width: 1100px){
    .txt{
        width: 95%;
        font-size: 12px;
        line-height: 15px;
    }
    .image{
        width: 380px;
    }
}
@media screen and (max-width: 900px){
    .txt{
        font-size: 11px;
        line-height: 14px;
    }
    .image{
        width: 300px;
    }
    .logo{
        width: 100px;
        height: 100px;
    }
    .header{
        font-size: 50px;
    }

}
@media screen and (max-width: 750px){
    .container{
        display: table;
        margin-top: 20px;
    }
    .txt{
        font-size: 10px;
        line-height: 18px;
    }
    .static{
        display: flex;
        flex-flow: column;
        margin-top: 0;
    }
    .div{
        margin-top: 10px;
        height: 50px;
        width: 80%;
    }
    .header{
        font-size: 40px;
    }
    .carousel{
        margin: 10px 0;
    }
    .number{
        font-size: 20px;
    }
}
@media screen and (max-width: 600px){
   .container{
       display: table;

   }
    .logo{
        width: 80px;
        height: 80px;
    }
}
@media screen and (max-width: 480px){
    .logo{
        width: 60px;
        height: 60px;
    }
}