.nav_container{
    width: 100%;
    height: 10vh;
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: space-between;
}
.logo{
    background:  darkgray;
    width: 50%;
}
.brand_name{
    display: flex;
    align-items: center;
}
.brand_name>a{
    font-family: 'Dancing Script', cursive;
    font-size: 50px;
    margin-left: 70px;
    text-decoration: none;
    color: black;
}
.menu_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.menu_container>a{
    font-size: 25px;
    font-family: 'Dancing Script', cursive;
    font-weight: 500;
    text-decoration: none;
    color: black;
}
@media screen and (max-width: 1200px){
    .brand_name>a{
        font-family: 'Dancing Script', cursive;
        font-size: 30px;
        margin-left: 70px;
    }
    .menu_container>a{
        font-size: 18px;
        font-family: 'Dancing Script', cursive;
        font-weight: 500;
    }
}
@media screen and (max-width: 900px){
    .brand_name>a{
        margin-left: 20px;
        font-size: 20px;
    }
    .menu_container>a{
        font-size: 12px;

    }
}