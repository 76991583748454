.footer_container{
    width: 100%;
    height: 40vh;
    margin-top: 50px;
    background: #382d7e;
    display: flex;
    grid-auto-columns: 1fr 1fr 1fr;
    align-items: center;
    color: white;
}
.text{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.text1{
    width: 100%;
    height: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.text1>div{
    display: flex;
    flex-flow: column;
    margin-top: 10px;
}
.text1>div>a{
    text-decoration: none;
    color: white;
}
.div{
    display: flex;
    align-items: center;
}
.div>span{
    margin-left: 5px;
    font-size: 20px;
}
.name{
    display: flex;
    font-size: 80px;
    font-family: 'Dancing Script', cursive;
    font-weight: 500;
}
.status{
    display: flex;
    font-size:40px;
    font-family: 'Dancing Script', cursive;
}
.image{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.image>img{
    height: 100%;
}
@media screen and (max-width: 1700px){
    .status{
        font-size: 25px;
    }
    .name{
        font-size: 60px;
    }
}
@media screen and (max-width: 1200px){
    .status{
        font-size: 20px;
    }
    .name{
        font-size: 50px;
    }
    .icon{
        width: 30px;
        height: 30px;
    }
.div>span{
    font-size: 15px;
}
}
@media screen and (max-width: 900px){
    .status{
        font-size: 18px;
    }
    .name{
        font-size: 40px;
    }
}
@media screen and (max-width: 750px){
    .footer_container{
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    .status{
        font-size: 12px;
    }
    .name{
        font-size: 30px;
    }
.icon{
    width: 20px;
    height: 20px;
}
.text{
    height: auto;
}
.text1{
    height: auto;
}
.div>span{
    font-size: 10px;
}
}
@media screen and (max-width: 480px){
    .status{
        font-size: 10px;
    }
    .name{
        font-size: 20px;
    }
    .icon{
        width: 20px;
        height: 20px;
    }
    .div>span{
        font-size: 10px;
    }
}