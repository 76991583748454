.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}
.main_container{
    width: 100%;
    height: 90vh;
    display: grid;
    grid-template-columns: 60% 40%;
}
.image_container{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.image{
    width: 100%;
    overflow: hidden;
}
.text_container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 70% 30%;
    background: #fafafa;
}
.name{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.name_txt{
    color: #fff;
    font-size: 120px;
    font-family: 'Dancing Script', cursive;
    background: linear-gradient(to right,  #382d7e 10%, #777777 50%, #bbadad 60%);
    background-clip: border-box;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;

}
.status_txt{
    display: flex;
    color: #5b5b5b;
    justify-content: center;
    font-size:40px;
    font-family: 'Dancing Script', cursive;
}
.cnt1>img{
    width: 250px;
}
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.status{
    display: flex;
    color: #2f266c;
    justify-content: center;
    font-size:50px;
    font-weight: 550;
    font-family: 'Dancing Script', cursive;
}
.slide_container{
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: space-evenly;
}
.cnt{
    width: 20%;
    height: 100%;
    /*background: #2f266c;*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.cnt>img{
    width: 200px;
}
.cnt2{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cnt2>img{
    width: 200px;
}
.cnt1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
}
.video_title{
    display: flex;
    justify-content: center;
}
.video_title>span{
    color: rgba(217,218,213,1);
    color:  #382d7e;
    font-size: 100px;
    text-align: center;
    font-family: 'Joane Stencil Regular';
    font-weight: 400;
    font-style: normal;
}
.videos_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    margin-top: 100px;
}
.videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    margin-top: 30px;
}
.videos>div{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}
.videos>div>iframe{
    width: 400px;
    height: 300px;
}
.about_container{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 100px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #f4f7fc;
}
.about_img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.about_img>img{
    width: 650px;
}
.text{
    display: flex;
    flex-flow: column;
}
.title{
    font-size: 100px;
    text-align: center;
    font-family: 'Joane Stencil Regular';
    font-weight: 400;
    color:  #382d7e;
    font-style: normal;
}
.txt{
    padding: 40px;
    font-size: 35px;
    line-height: 55px;
    font-family: "Helvetica Neue";
}
.books_container{
    width:100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    margin-top: 100px;
}
.book_title{
    font-size: 100px;
    text-align: center;
    color:  #382d7e;
    font-family: 'Joane Stencil Regular';
    font-weight: 400;
    font-style: normal;
}
.books{
    width: 100%;
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
}
.books>div>img{
    width: 400px;
    box-shadow: 2px 5px 4px 3px #5b5b5b;
}
@media screen  and (max-width: 1900px){
    .about_img>img{
        width: 600px;
    }
    .title{
        font-size: 90px;
    }
    .txt{
        font-size: 30px;
        line-height: 45px;
    }
}
@media screen  and (max-width: 1800px){
    .about_img>img{
        width: 550px;
    }
    .title{
        font-size: 80px;
    }
}
@media screen  and (max-width: 1700px){
    .name_txt{
        font-size: 90px;
    }
.status_txt{
    font-size: 30px;
}
.status{
    font-size: 40px;
}
    .about_img>img{
        width: 500px;
    }
    .title{
        font-size: 70px;
    }
    .txt{
        font-size: 25px;
        line-height: 35px;
    }
    .books>div>img{
        width: 300px;
    }
    .cnt>img{
        width: 150px;
    }
    .cnt1>img{
        width: 150px;
    }
    .cnt2>img{
        width: 150px;
    }
}

@media screen  and (max-width: 1400px){
    .about_img>img{
        width: 450px;
    }
    .title{
        font-size: 60px;
    }
    .txt{
        font-size: 22px;
        line-height: 37px;
    }
}
@media screen  and (max-width: 1300px){

    .books>div>img{
        width: 250px;
    }
    .videos>div>iframe{
        width: 300px;
        height: 250px;
    }

}
@media screen and (max-width: 1200px){
    .name_txt{
        font-size: 70px;
    }
    .status_txt{
        font-size: 25px;
    }
    .status{
        font-size: 30px;
    }
    .cnt2>img{
        width: 150px;
    }
    .cnt>img{
        width: 100px;
    }
    .cnt1>img{
        width: 150px;
    }
    .txt{
        font-size: 18px;
        line-height: 30px;
    }
    .title {
        font-size: 50px;
    }
}
@media screen and (max-width: 1100px){
    .name_txt{
        font-size: 60px;
    }
    .status_txt{
        font-size: 20px;
    }
    .status{
        font-size: 25px;
    }
    .books>div>img{
        width: 200px;
    }
    .videos>div>iframe{
        width: 250px;
        height: 200px;
    }

}
@media screen and (max-width: 900px){
    .name_txt{
        font-size: 50px;
    }
    .status_txt{
        font-size: 20px;
    }
    .txt{
        padding: 30px;
        font-size: 15px;
        line-height: 30px;
    }
    .about_img>img{
        width: 380px;
    }
    .title{
        font-size: 40px;
    }
    .books>div>img{
        width: 150px;
    }
}
@media screen and (max-width: 750px){
    .name_txt{
        font-size: 40px;
    }
    .status_txt{
        font-size: 15px;
    }
    .status{
        font-size: 18px;
    }
    .txt{
        font-size: 12px;
        line-height: 25px;
    }
    .about_img>img{
        width: 340px;
    }
    .title{
        font-size: 30px;
    }
    .books_container{
        justify-content: center;
        margin-top: 20px;
    }
    .books{
        display:  grid;
        margin-top: 0;
        margin-bottom: 0;
        grid-template-columns: 1fr 1fr;
    }
    .videos>div>iframe{
        width: 200px;
        height: 200px;
    }
    .books>div{
      display: flex;
      justify-content: center;
      align-items: center
    }
    .books>div>img{
        width: 200px;
        margin-top: 20px;
    }
    .about_container{
        display: table;
        margin-top: 0;
    }
    .videos_container{
        margin-top: 30px;
    }
    .videos{
        margin-top: 10px;
    }

}
@media screen and (max-width: 600px){
    .name_txt{
        font-size: 30px;
    }
    .status_txt{
        font-size: 12px;
    }
    .main_container{
        height: 100%;
    }
    .txt{
        padding: 25px;
        font-size: 11px;
        line-height: 20px;
    }
    .about_img>img{
        width: 300px;
    }
    .videos>div>iframe{
        width: 150px;
        height: 100px;
    }
}
@media screen and (max-width: 480px){
    .name_txt{
        font-size: 20px;
    }
    .status_txt{
        font-size: 10px;
    }
    .main_container{
        height: 100%;
    }
    .status{
        font-size: 12px;
    }
    .about_img>img{
        width: 200px;
    }
.title{
    font-size: 15px;
}
    .books>div>img{
        width: 150px;
    }
    .videos>div>iframe{
        width: 100px;
        height: 50px;
    }
    .cnt2>img{
        width: 100px;
    }
    .cnt>img{
        width: 50px;
    }
    .cnt1>img{
        width: 100px;
    }
}